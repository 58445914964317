<template>
  <v-dialog
    v-model="show"
    @click:outside="fechar"
    @keydown.esc="fechar"
    width="1200px"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-2">
        <span class="px-2">
          {{ $tc("global.venda", 2) + " " + $tc("global.pendentes", 2) }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-2">
        <v-data-table
          :headers="headers"
          :items="vendas"
          :sort-desc="true"
          :items-per-page="-1"
          hide-default-footer
          class="data-tables data-tables__row-click"
          @click:row="iniciarBaixaVenda"
        >
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.condicao_pagamento="{ item }">
            {{
              item.condicao_pagamento == 1
                ? $tc("global.aVista")
                : $tc("global.aPrazo")
            }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>
          <template v-slot:item.cliente_nome="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.cliente_nome | excerpt(20) }}
                </div>
              </template>
              <span>{{ item.cliente_nome }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.vendedor_nome="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.vendedor_nome | excerpt(20) }}
                </div>
              </template>
              <span>{{ item.vendedor_nome }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click.stop="openVendaInfo(item)"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.informacoes") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog v-model="dialogDevolucao" width="500">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            {{ $tc("global.desejaDevolver") }}
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="motivo_devolucao"
              :label="$tc('global.motivo') + ' ' + $tc('global.devolucao')"
              outlined
            ></v-text-field>
            <span class="text-caption">
              {{ $tc("global.mincaracteres", 10) }}
            </span>
          </v-card-text>

          <v-card-actions>
            <v-btn color="error" text @click="dialogDevolucao = false">
              {{ $tc("global.cancelar") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="button_2"
              :disabled="semMotivo"
              class="white--text"
              @click="retornarVenda(devolucao_id)"
            >
              <v-icon left>mdi-arrow-u-left-top</v-icon>
              {{ $tc("global.devolver") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DialogBaixaVenda
        v-if="dialogBaixaVenda"
        :dialogBaixaVenda.sync="dialogBaixaVenda"
        :venda="venda"
        :venda_parcelas="venda_parcelas"
        @add="addVenda"
        @fetch-vendas-caixa="fetchVendas"
        @fecharVendas="fechar"
      />
      <DialogVendaInfo
        v-if="dialogVendaInfo"
        :dialogVendaInfo.sync="dialogVendaInfo"
        :venda="venda"
        :venda_parcelas="venda_parcelas"
        @fetch-vendas-caixa="fetchVendas"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getVendasCaixa,
  iniciaBaixaVenda,
  retornaVenda,
  getVendaInfo,
  fetchVenda,
} from "@/api/vendas/vendas.js";
export default {
  name: "DialogVendasEmLoteAf",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
    },
  },

  components: {
    DialogBaixaVenda: () => import("./DialogBaixaVendaEmLoteAf.vue"),
    DialogVendaInfo: () => import("../../DialogVendaInfo.vue"),
  },

  data() {
    return {
      vendas: [],
      venda: {},
      venda_parcelas: [],
      dialogBaixaVenda: false,
      dialogDevolucao: false,
      dialogVendaInfo: false,
      motivo_devolucao: "",
      devolucao_id: null,
    };
  },

  computed: {
    semMotivo() {
      let result = false;

      if (
        this.motivo_devolucao === "" ||
        this.motivo_devolucao === null ||
        this.motivo_devolucao === undefined ||
        this.motivo_devolucao.length < 10
      ) {
        result = true;
      }

      return result;
    },
    headers() {
      return [
        {
          text: this.$tc("global.numero"),
          width: "60px",
          align: "center",
          sortable: false,
          value: "numero",
          divider: true,
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
          divider: true,
        },
        {
          text: this.$tc("global.tipo") + " " + this.$tc("global.venda"),
          width: "180px",
          value: "tipo_venda",
          divider: true,
        },
        {
          text: this.$tc("global.vendedor"),
          width: "130px",
          value: "vendedor_nome",
          divider: true,
        },
        {
          text: this.$tc("global.pagamento"),
          value: "condicao_pagamento",
          width: "100px",
          align: "start",
          sortable: false,
          divider: true,
        },

        {
          text: this.$tc("global.valor"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
          divider: true,
        },
        {
          text: "",
          width: "20px",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },

  methods: {
    addVenda(transacao) {
      this.$emit("add", transacao);
    },
    async openVendaInfo(item) {
      await getVendaInfo(item.id)
        .then((response) => {
          this.venda = response;
          if (this.venda.parcelas) {
            this.venda_parcelas = this.venda.parcelas;
          }
          this.dialogVendaInfo = true;
        })
        .catch(() => {});
    },
    retornarVenda(venda) {
      let devolucao = {};
      devolucao.motivo = this.motivo_devolucao;

      retornaVenda(venda, devolucao)
        .then(() => {
          this.dialogDevolucao = false;
          this.motivo_devolucao = "";
          this.fetchVendas();
        })
        .catch(() => {});
    },
    async iniciarBaixaVenda(item) {
      await iniciaBaixaVenda(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.venda = response.data.result;
            if (this.venda.parcelas) {
              this.venda_parcelas = this.venda.parcelas;
            }
            this.dialogBaixaVenda = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error(this.$tc("global.vendaBaixa"));
          }
        });
    },

    fetchVendas() {
      const clienteId = this.cliente ? this.cliente.id : null; 
      getVendasCaixa(clienteId)
        .then((response) => {
          this.vendas = response;
        })
        .catch(() => {});
    },

    fechar() {
      this.$emit("update:show", false);
    },
  },
  mounted() {
    this.fetchVendas();
  },
};
</script>

<style></style>
